import React, { useEffect, useState } from "react";
import style from "./Navigation.module.scss";
import { Link } from "gatsby";
import cx from "classnames";

const Navigation = ({ contattiCallBack }) => {
  const [background, setBackground] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY === 0) {
        setBackground(false);
      }
      if (window.scrollY !== 0) {
        setBackground(true);
      }
    });
  }, []);

  return (
    <div className={cx(style.navigation, { [style.background]: background === true })}>
      <div className={style.logo} />
      <div className={style.links}>
        <Link to={"/"}>Home.</Link>
        <Link to={"/chi-siamo"}>Chi siamo.</Link>
        <Link to={"/cosa-facciamo"}>Cosa facciamo.</Link>
        <Link to={"/assicurativo"}>Piattaforma assicurativa.</Link>
        <Link to={"/affiliati"}>Programma Affiliati.</Link>
      </div>
      {contattiCallBack && (
        <button className={style.contattaci_link} onClick={() => contattiCallBack()}>
          Contattaci
        </button>
      )}
    </div>
  );
};

export default Navigation;
