import React from "react";
import cx from "classnames";

/**
 * Max space 20 (* 8)
 */
const Space = ({mobile = 5, tablet = 5, desktop = 5}) => {
  const mobileMargin = mobile <= 10 ? `pt-${mobile} pb-0` : `pt-10 pb-${mobile - 10}`;
  const tabletMargin = tablet <= 10 ? `pt-md-t-${tablet} pb-md-0` : `pt-md-10 pb-md-${tablet - 10}`;
  const desktopMargin = desktop <= 10 ? `pt-lg-${desktop} pb-lg-0` : `pt-lg-10 pb-lg-${desktop - 10}`;

  return <div className={cx(mobileMargin, tabletMargin, desktopMargin)} />;
};

export default Space;
