import React from "react";
import Helmet from "react-helmet";

//we need only grid system
import "../style/style.scss";
import SEO from "./SEO";

const Page = ({children, title, description, image}) => (
  <>
    <Helmet>
      <link href='https://fonts.googleapis.com/css2?family=Catamaran:wght@400;500;600;700&family=Roboto:wght@400;700&display=swap' rel='stylesheet' />
    </Helmet>
    <SEO lang='it' title={title} description={description} image={image} />
    {children}
  </>
);

export default Page;
