import React from "react";
import style from "./Button.module.scss";
import cx from "classnames";

const Button = ({children, onClick, to, customClass = "", outline = false, black = false, ...props}) => {
  const classes = cx(style.button, customClass, {[style.button_green]: !black}, {[style.button_black]: black}, {[style.outline]: outline});

  //link
  if (to) {
    return (
      <a className={classes} href={to} {...props}>
        {children}
      </a>
    );
  }

  //default return is "onclick" one
  return (
    <button className={classes} onClick={onClick} {...props}>
      {children}
    </button>
  );
};

export default Button;
